@import "style-guide";

.dropdown {
  position: relative;
}

.triangle {
  position: absolute;
  right: 0;
  top: 2*$u;
  width: 0;
  height: 0;
  border-top: 2*$u solid transparent;
  border-bottom: 2*$u solid transparent;
  border-right: 2*$u solid $brand-dark;
  transition: all $defaultAnimationDuration;
  opacity: 1;
  transform: translateX(0);

  &--isHide {
    opacity: 0;
    transform: translateX(2*$u);
  }

  &--isRed {
    border-right-color: $tomato-red;
  }
}

.input {
  position: relative;
}

.trigger {
  display: flex;
  flex-direction: row;
}

.trigger-input {
  flex-grow: 1;
  border: solid $inputBorderWidth transparent;

  @include base-font;
  @include Input-Value-Bold-font;

  padding: ((2*$u) - $inputBorderWidth) ((3*$u) - $inputBorderWidth);
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $inputHoverBackgroundColor;
  }

  &--hasIcon {
    padding-left: (8 * $u) - $inputBorderWidth;
  }

  &--hasClearControl {
    padding-right: (8 * $u) - $inputBorderWidth;
  }

  &--isFocused {
    background-color: $inputFocusBackgroundColor !important;
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor !important;
    border-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
    }
  }

  &--isEmpty {
    border-color: transparent;

    @include Input-Value-Placeholder-font;
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }

  &__icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  background-color: $white;

  @include control-shadow;

  border-radius: $controlDefaultBorderRadius;
  z-index: $z-index-overlay;
  animation: fadeIn 0.1s ease-in;
  max-height: 60*$u;
  overflow-y: auto;

  &--position-top {
    bottom: 100%;
  }

  &--position-bottom {
    top: 100%;
  }

  &__option {
    padding: 2*$u 3*$u;
    cursor: pointer;

    @include Dropdown-ListBold-font;

    &:hover {
      background-color: $optionHoverBackgroundColor;
    }

    &--isSelected {
      background-color: $inputHoverBackgroundColor;

      &:hover {
        background-color: $inputHoverBackgroundColor;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.right-node {
  position: absolute;
  right: $u;
  top: $u;
  margin-right: $u;
  display: flex;
  align-items: center;
  gap: $u;
}
