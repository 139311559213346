@import "style-guide";

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3*$u;
}

.name {
  @include Input-Value-Normal-font;
}

.utilization {
  padding-left: $u;

  @include Utilization-Normal-font;

  white-space: nowrap;
}

.spacer {
  flex-grow: 1;
}
