@import "style-guide";

.row {
  white-space: nowrap;
  margin-bottom: $u;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  margin-right: $u;
  vertical-align: top;
}

.text {
  @include Asset-Pin-Text-font;

  &--isSelected {
    color: $brand-accent;
  }
}
