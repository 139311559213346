@import "style-guide";

$verticalPadding: 6 * $u;
$horizontalPadding: 6 * $u;

.notification-toasts-manger {
  position: fixed;
  overflow: hidden;
  pointer-events: auto;
  right: $horizontalPadding;
  top: $verticalPadding;
  z-index: $z-index-notifications-panel;
}

.dev-localization-language-switcher {
  position: fixed;
  pointer-events: auto;
  right: $horizontalPadding;
  bottom: $verticalPadding;
  z-index: $z-index-dev-localization-language-panel;
}

.alerts-manager {
  position: fixed;
  overflow: hidden;
  pointer-events: auto;
  right: $horizontalPadding;
  bottom: $verticalPadding;
  z-index: $z-index-alerts-panel;
}

.debug-notifications-form {
  position: fixed;
  overflow: hidden;
  top: $verticalPadding;
  left: $horizontalPadding;
  z-index: $z-index-notifications-panel;
  width: 100*$u;
}

.debug-alerts-form {
  position: fixed;
  overflow: hidden;
  bottom: $verticalPadding;
  left: $horizontalPadding;
  z-index: $z-index-alerts-panel;
  width: 100*$u;
}
