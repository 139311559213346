@import "style-guide";

.button {
  width: 100%;
}

.form {

}

.alerts {
  margin-bottom: 6*$u;
}

.field {
  &--emission-standard {
    width: 50%;
  }

  &--vin,
  &--country-code,
  &--plate-number,
  &--placeholder {
    width: 50%;
  }

  &--cabin-color {
    width: 50%;
  }

  &--contract {
    width: 50%;
  }

  &--rate {
    width: 50%;
  }

  &--fuel-consumption {
    width: 50%;
  }

  &--oil-percent {
    width: 50%;
  }
}

.fields {
  margin-bottom: 3*$u;
}

.actions {
  display: flex;

  &__action {
    width: 50%;

    &--cancel {
      margin-right: 2*$u;
    }

    &--delete {
      margin-left: 2*$u;
    }
  }
}

.footer-warning {
  margin: 0 6*$u 2*$u;

  &:last-of-type {
    margin-bottom: 0;
  }
}
