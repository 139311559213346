@import "style-guide";

.inner {
  display: flex;
  flex-direction: row;
  border-radius: $controlDefaultBorderRadius;

  &--is-align-items-center {
    align-items: center;
  }

  &--theme-brand-accent {
    background-color: $brand-accent;
  }

  &--theme-tomato-red {
    background-color: $tomato-red;
  }

  &--theme-orange {
    background-color: $orange;
  }

  &--theme-gray {
    background-color: $gray;
  }

  &--theme-charcoal {
    background-color: $charcoal;
  }

  &__size-small {
    padding: $u;
  }

  &__size-medium {
    padding: 4*$u;
  }
}

.icon {
  margin: $u;

  &__size-small {

  }

  &__size-medium {

  }
}

.text {
  flex-grow: 1;
  margin: $u;

  @include Alert-WhiteMessage-font;
}

.close {
  max-height: 6*$u;
}

.action {
  margin-right: $u;
  max-height: 6*$u;
}

.action-text {
  @include Alert-WhiteAction-font;

  padding: 0 3*$u;
  vertical-align: top;
  text-transform: uppercase;
  position: relative;
  top: 1px;
}

.tooltip {
  margin: 5px;
}
